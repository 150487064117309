import React from "react";
import tw from "twin.macro";
import styled from "@emotion/styled";
import { graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import SearchEngineOptimization from "../components/SEO";
// import Testimonials from "../components/Repeating/Testimonials";
import WhyUs from "../components/Repeating/WhyUs";
// import RecentPosts from "../components/Repeating/RecentPosts";
import HarborViewLifestyle from "../components/Repeating/HarborViewLifestyle";
import Services from "../components/Repeating/Services";
import CallToAction from "../components/Repeating/CTA";
// import ButtonGhost from "../components/Button/ButtonGhost";
import ButtonGhostMobile from "../components/Button/ButtonGhostMobile";
import ButtonSolid from "../components/Button/ButtonSolid";
const StyledContent = styled.div`
  .dot-ending {
    ${tw`before:absolute before:bg-tertiary-400 before:block before:bottom-[10px] before:h-[16px] before:leading-[75px] before:right-[-16px] before:rounded-full before:w-[16px] inline-block relative rounded-full`}
  }
`;

const IndexPage = ({ data }) => {
  return (
    <Layout siteSettings={data.siteSettings} contactInfo={data.contactInfo}>
      <SearchEngineOptimization
        title="MesaView Senior Assisted Living | La Mesa, CA"
        description="MesaView Senior Assisted Living is a memory care community in La Mesa that's dedicated to providing gentle, individualized care. Call to schedule a tour."
        // openGraphImage={data.openGraphImage.publicURL}
        // twitterOpenGraphImage={data.twitterOpenGraphImage.publicURL}
      />

      {/* START OF HERO */}
      <section className="relative flex h-full flex-col justify-center bg-primary-500 md:block md:bg-transparent lg:flex lg:h-[350px] lg:pt-18 xl:h-[55vh] xl:pt-0 ">
        {/* START OF DESKTOP UNDERLAY */}
        <div className=" absolute left-0 mt-[50px] hidden w-full md:mt-[30px] md:block  lg:-bottom-[250px]">
          <StaticImage
            src="../images/1.0 Homepage/1.0 hero-desktop.png"
            loading="eager"
            placeholder="none"
            objectPosition="center"
            imgClassName="block h-full"
          />
        </div>
        {/* END OF DESKTOP UNDERLAY */}
        {/* START OF HERO CONTENT */}
        <div className="relative  pt-0 text-center md:pt-12 lg:pt-[inherit] ">
          <div>
            {/* START OF DESKTOP */}
            <div className="hidden lg:block">
              <div className="grid-col-1 container grid grid-flow-row gap-y-4">
                <StyledContent>
                  <p className="my-0 mx-auto block py-0 font-heading text-[46px] font-bold leading-[61px] text-white  ">
                    A place they’ll be happy to call home.
                  </p>
                </StyledContent>
                <p className="mx-auto my-0 block max-w-[750px] py-0 font-body text-[26px] font-thin leading-[36px] text-white ">
                  La Mesa’s premier boutique memory care and assisted living
                  community.
                </p>
                <ButtonSolid className="mx-auto mt-4" modal="modal-contact" text="Contact Us" />
              </div>
            </div>
            {/* END OF DESKTOP */}
            {/* START OF TABLET */}
            <div className="hidden  md:block lg:hidden">
              <div className="container">
                <StyledContent>
                  <p className="my-0 mx-auto block pt-10 pb-4 font-heading text-[36px] font-bold leading-[60px] text-white  ">
                    A place they’ll be happy to call home.
                  </p>
                </StyledContent>
                <p className="mx-auto my-0 block max-w-[750px] pt-0 pb-4 font-body text-[26px] font-thin leading-[36px] text-white">
                  La Mesa’s premier boutique memory care and assisted living
                  community.
                </p>
                <ButtonSolid 
                className="mt-4 mx-auto"
                modal="modal-contact" text="Contact Us" />
              </div>
            </div>
            {/* END OF TABLET */}
            {/* START OF MOBILE */}
            <div className="block  md:hidden">
              <div className="container my-[56px] py-4 text-center md:my-auto">
                <p className=" font-heading text-[36px] font-bold leading-[50px] text-white">
                  A place they’ll be happy <br></br>to call home.
                </p>
                <p className="text-[20px] font-thin leading-[34px] text-white">
                  La Mesa’s premier boutique memory care and assisted living
                  community.
                </p>
                <ButtonSolid altStyle={2} modal="modal-contact" text="Contact Us" />
              </div>
              <div className="block">
                <StaticImage
                  src="../images/1.0 Homepage/1.0 hero-mobile.jpg"
                  loading="eager"
                  placeholder="none"
                  className="h-full w-full"
                  imgClassName="object-top"
                />
              </div>
            </div>
            {/* END OF MOBILE */}
          </div>
        </div>
        {/* END OF HERO CONTENT  */}
      </section>
      {/* END OF HERO */}

      {/* START OF PAGE CONTENT */}
      <section className="mb-20 pt-[45px] md:mb-32 md:pt-[22rem] lg:pt-60 xl:pt-[7.5rem]">
        <div className="container">
          <div className="grid items-center gap-y-10 md:grid-cols-2 md:gap-x-10 lg:gap-x-20">
            <div>
              <h1 className="">
                Top Alzheimer’s & Dementia Care Serving La Mesa, CA
              </h1>
              <p>
                MesaView Senior Assisted Living is La Mesa’s premier boutique
                independent living, assisted living, and memory care community.
                Situated near downtown La Mesa, we’re just a short walk to local
                stores, restaurants, and coffee shops, as well as close to many
                major public services and hospitals. At MesaView, we’re
                dedicated to compassionate, personalized care and support
                services for residents. In our home-like setting, your loved
                ones can age in place gracefully and with dignity.
              </p>
            </div>
            <div>
              <StaticImage
                src="../images/1.0 Homepage/2.0 intro.png"
                alt="Top Alzheimer’s & Dementia Care Serving Encinitas & North County, CA"
                width={560}
                loading="eager"
                placeholder="none"
              />
            </div>
          </div>
        </div>
      </section>
      {/* END OF PAGE CONTENT */}
      {/* START OF PAGE COMPONENTS */}
      <Services />
      <HarborViewLifestyle
        shadow={true}
        className="mb-14  bg-gray-50 p-4 md:mb-24 lg:p-24 "
        hide={4}
      />
      <WhyUs />
      {/* <Testimonials /> */}
      {/* <RecentPosts /> */}
      <CallToAction phonenumber={data.contactInfo.phonenumber} />
      {/* END OF PAGE COMPONENTS */}
    </Layout>
  );
};

export const data = graphql`
  {
    openGraphImage: file(
      relativePath: { eq: "open-graph/facebook/Facebook.jpg" }
    ) {
      publicURL
    }
    twitterOpenGraphImage: file(
      relativePath: { eq: "open-graph/twitter/Twitter.jpg" }
    ) {
      publicURL
    }
    sanitySiteSettings {
      title
    }

    allSanityServices {
      nodes {
        title
        _id
      }
      totalCount
    }
    contactInfo: sanityContactinfo {
      title
      description
      phonenumber
      email
      address
      license
      bookingurl
    }
    siteSettings: sanitySiteSettings {
      title
      description
      author
      headerlogo {
        asset {
          gatsbyImageData
        }
      }
      footerlogo {
        asset {
          gatsbyImageData
        }
      }
      facebookurl
      twitterurl
      privacypolicy
    }
  }
`;

export default IndexPage;
